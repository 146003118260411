import { useRef } from 'react';
import styled from '@emotion/styled';

import { HeadingLevelProvider } from '@/components/common/Heading/context';
import { SkipToContentButton } from '@/components/common/SkipToContentButton';
import { Footer } from '@/components/layout/Footer';
import { NavigationBar } from '@/components/layout/NavigationBar';
import { SharedLayout } from '@/components/layout/SharedLayout';
import { useNewNavigation } from '@/lib/useNewNavigation';
import { SiteSettingsProvider } from '@/lib/useSiteSettings';

import type { SharedLayoutProps } from '@/components/layout/SharedLayout';
import type { SanityAudienceType, SanityNavigationType } from '@/types/sanity';
import type { Navigation_exType, SiteSettings } from '@/types/shared';

interface LayoutProps extends SharedLayoutProps {
  preview?: boolean;
  siteSettings: SiteSettings;
  navigation?: SanityNavigationType;
  audienceRef?: SanityAudienceType;
  navigations_ex?: Navigation_exType[];
}

export const Layout = ({
  children,
  SEO,
  preview = false,
  siteSettings,
  localeTranslations,
  canonicalPagePath,
  navigation,
  audienceRef,
  navigations_ex,
  recommendationContext,
}: LayoutProps) => {
  const isIntercomEnabled = !!siteSettings?.isIntercomEnabled;

  const mainRef = useRef<HTMLElement | null>(null);

  const navigation_ex = useNewNavigation(
    navigations_ex,
    siteSettings,
    audienceRef,
  );

  return (
    <SiteSettingsProvider siteSettings={siteSettings}>
      <SharedLayout
        localeTranslations={localeTranslations}
        canonicalPagePath={canonicalPagePath}
        isIntercomEnabled={isIntercomEnabled}
        recommendationContext={recommendationContext}
        SEO={SEO}
        preview={preview}
      >
        <HeadingLevelProvider>
          <SkipToContentButton focusElement={mainRef} />

          {(navigation_ex || navigation) && (
            <NavigationBar
              navigation={navigation}
              navigation_ex={navigation_ex}
              audiences={
                siteSettings?.isAudienceSwitcherEnabled &&
                siteSettings?.newNavigation &&
                navigations_ex
                  .map((navigation_ex) => navigation_ex.audience)
                  .filter((audience) => audience !== null)
              }
              siteSettings={siteSettings}
            />
          )}

          <MainContainer ref={mainRef} tabIndex={-1}>
            {children}
          </MainContainer>

          <Footer {...siteSettings} />
        </HeadingLevelProvider>
      </SharedLayout>
    </SiteSettingsProvider>
  );
};

export const MainContainer = styled.main`
  position: relative;
  z-index: 2;
  min-height: 40vh;
  overflow: clip;
  box-shadow: var(--shadow-base);
  border-bottom-left-radius: var(--section-radius);
  border-bottom-right-radius: var(--section-radius);

  // Get direct decendants, or direct decendants if a container with sections within
  >,
  > :first-child:has(section) {
    // Get first item if it's a section and direct decendant, or if it's a child of a div like .dy-blocks-container
    section:first-child {
      margin-bottom: calc(var(--section-radius) * -1);
      padding-bottom: calc(
        var(--section-radius) + var(--first-child-padding, 0px)
      );
    }

    // Any Section following the first child has the rounded top
    *:first-child + section {
      border-top-left-radius: var(--section-radius);
      border-top-right-radius: var(--section-radius);
      box-shadow: 0px -4px 54px rgb(0, 0, 0, 10%);
    }
  }
`;
